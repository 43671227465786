<template>
  <div class="kb" id="kb">
    <header class="kb-header" id="kb-header">
      <div class="header-component">
        <!-- header-title -->
        <div class="header-column header-title">
          불참(취소)/회차변경
        </div>
        <!-- //header-title -->
        <!-- header-util -->
        <div class="header-column header-util left">
          <div class="util util-back">
            <a href="javascript:" class="util-actions util-actions-back" @click="clickBack">
              <i class="icon-history-back"></i>
            </a>
          </div>
        </div>
        <!-- //header-util -->
      </div>
    </header>

    <main class="kb-main" id="kb-educourse">
      <div class="main-content main-component">
        <div class="educourse-view-container course-cancel">
          <!-- kb-form-fields -->
          <div class="view-contents">
            <section class="content-section">
              <header class="section-header gold">
                <h4 class="title">{{ dist.crseNm }}</h4>
              </header>
              <div class="section-content">
                <div class="content-item" v-if="editable.rtrcn">
                  <label for="chk_01_01" class="item-agree">취소
                    <div class="kb-form-check">
                      <input v-model="regCrseAplySttDcd" type="radio" class="kb-form-check-input" name="chk_01"
                             id="chk_01_01" :value="crseAplyDcds.REG_CRSE_APLY_STT_CANCEL">
                    </div>
                  </label>
                </div>
                <div class="content-item" v-if="editable.absd">
                  <label for="chk_01_03" class="item-agree">불참(취소)
                    <div class="kb-form-check">
                      <input v-model="regCrseAplySttDcd" type="radio" class="kb-form-check-input" name="chk_01"
                             id="chk_01_03" :value="crseAplyDcds.REG_CRSE_APLY_STT_NOSW">
                    </div>
                  </label>
                </div>
                <div class="content-item border-0 pt-0" v-if="editable.seqChg">
                  <label for="chk_01_02" class="item-agree">회차변경
                    <div class="kb-form-check">
                      <input v-model="regCrseAplySttDcd" type="radio" class="kb-form-check-input" name="chk_01"
                             id="chk_01_02" :value="crseAplyDcds.REG_CRSE_APLY_STT_CHANGE">
                    </div>
                  </label>
                </div>
                <template v-if="regCrseAplySttDcd === crseAplyDcds.REG_CRSE_APLY_STT_CHANGE">
                  <div class="content-item">
                    <div class="title">회차선택 </div>
                    <div class="desc">
                      <SortButtonSelect3
                          v-model="chgDistCrseSn"
                          v-model:toggle="relToggle"
                          title="차수를 선택해주세요."
                          :options="otherRels"
                          sequence-key="distCrseSn"
                          name-key="crseNm"
                          :is-row="true"
                          :is-num="true"
                          class="kb-btn-course-select"
                      />
                    </div>

                  </div>
                </template>
              </div>
              <div v-if="regCrseAplySttDcd === crseAplyDcds.REG_CRSE_APLY_STT_CHANGE" class="content-item">
                <div class="title">사유 </div>
                <div class="desc">
                  <p><textarea class="" placeholder="사유를 입력하세요." v-model="reason" style="height: 90px"></textarea></p>
                </div>
              </div>
              <div class="content-item">
                <label class="item-agree" for="chk_agree_01">
                  <span v-if="regCrseAplySttDcd === crseAplyDcds.REG_CRSE_APLY_STT_CANCEL" class="text">연수신청을 취소하고, 연수대상자에서 제외됨을 확인합니다.</span>
                  <span v-else-if="regCrseAplySttDcd === crseAplyDcds.REG_CRSE_APLY_STT_CHANGE" class="text">다른 회차로의 변경을 신청합니다. 반려되면 기존입과가 유지됩니다.</span>
                  <span v-else-if="regCrseAplySttDcd === crseAplyDcds.REG_CRSE_APLY_STT_NOSW" class="text">입과된 연수의 불참(취소)을 신청합니다. 반려되면 입과가 유지됩니다.</span>
                  <div class="kb-form-check"><input v-model="isAgree" type="checkbox" class="kb-form-check-input" id="chk_agree_01"></div>
                </label>
              </div>

            </section>
          </div>
          <div class="page-buttons">
            <button class="kb-btn kb-btn-primary" @click="clickChange"><span class="text">확인</span></button>
          </div>
        </div>
      </div>

    </main>
  </div>
</template>

<script>


import {computed, reactive, ref, onMounted} from 'vue';
import {getResult, isSuccess, timestampToDateFormat} from '@/assets/js/util';
import {getListFunc, getFunc} from '@/assets/js/ui.init';
import {
  ACT_GET_CRSE_REL_LIST,
  ACT_UPDATE_CRSE_REG_APLY,
  ACT_GET_CRSE_DIST_OBJ_APPLY,
  ACT_GET_CRSE_DIST_OBJ_LRNER,
  ACT_GET_DIGITAL_DTL
} from '@/store/modules/course/course';
import SortButtonSelect3 from '@/components/common/SortButtonSelect3';
import {crseAplyDcds} from '@/assets/js/modules/common/common';
import {useAlert} from '@/assets/js/modules/common/alert';
import {useStore} from 'vuex';
import {useRoute, useRouter} from 'vue-router';
import {goBack} from '@/assets/js/modules/common/common';
import {ACT_GET_CRSE_REG_APLY_LIST} from '@/store/modules/course/course';
import {ACT_GET_LEARN_HISTORY_LIST} from '@/store/modules/learn/learn';

export default {
  name: 'ApplyStatusEdit',
  components: {SortButtonSelect3},
  setup() {

    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const {showConfirm, showMessage} = useAlert();
    const distCrseSn = computed(() => route.params.distCrseSn);
    const paging = reactive({
      pageNo: 1,
      pageSize: 100,
      totalCount: 0,
    });
    const session = computed(() => store.state.auth.session);

    const isLoading = ref(false);
    const isAgree = ref(false);
    const regCrseAplySttDcd = ref('');
    const reason = ref('');
    const dist = ref({});
    const lrnerObjs = ref([]);
    const learns = ref([]);
    const objItems = ref([]);

    const editable = reactive({
      rtrcn: false,
      seqChg: false,
      absd: false,
      evl: false,
    });

    const dtlItem = reactive({
      lrnerId: 0,
      reqYn: '',
      digiDeptTyDcd: '',
      digiDeptTyNm: '',
      preYn: '',
      basicYn: '',
      advnYn: '',
    });

    const setEditable = () => {
      // console.log(dist.value);
      const currentTs = new Date().getTime();
      // 입과대기상태일 경우 - 취소만가능
      if (dist.value.lrnTrgtGrpDtlSn === 0) {
        if (dist.value.rtrcnPsbltYn === 'Y') {
          editable.rtrcn = (currentTs >= dist.value.rtrcnBgngDt && currentTs < dist.value.rtrcnEndDt);
        }
        // 입과완료상태일 경우 - 불참,회차변경 가능
      } else {
        if (dist.value.absdAplyYn === 'Y') {
          editable.absd = (currentTs >= dist.value.absdAplyBgngDt && currentTs < dist.value.absdAplyEndDt);
        }
        if (dist.value.seqChgYn === 'Y') {
          editable.seqChg = (currentTs >= dist.value.seqChgBgngDt && currentTs < dist.value.seqChgEndDt);
        }
      }

      if (editable.rtrcn) {
        regCrseAplySttDcd.value = crseAplyDcds.REG_CRSE_APLY_STT_CANCEL;
      } else if (editable.absd) {
        regCrseAplySttDcd.value = crseAplyDcds.REG_CRSE_APLY_STT_NOSW;
      } else if (editable.seqChg) {
        regCrseAplySttDcd.value = crseAplyDcds.REG_CRSE_APLY_STT_CHANGE;
      }
      // console.log(editable);
    };

    const changeMessages = computed(() => {
      if (regCrseAplySttDcd.value === crseAplyDcds.REG_CRSE_APLY_STT_CANCEL) {
        return {
          title: '신청취소',
          text: '신청취소를 신청하시겠습니까?',
          agree: '신청취소',
          complete: '신청취소가 신청되었습니다.',
        };

      } else if (regCrseAplySttDcd.value === crseAplyDcds.REG_CRSE_APLY_STT_NOSW) {
        return {
          title: '불참(취소)',
          text: '불참(취소)을 신청하시겠습니까?',
          agree: '불참(취소) ',
          complete: '불참(취소)이 신청되었습니다.',
        };

      } else if (regCrseAplySttDcd.value === crseAplyDcds.REG_CRSE_APLY_STT_CHANGE) {
        return {
          title: '회차변경',
          text: '회차변경을 신청하시겠습니까?',
          agree: '회차변경 ',
          complete: '회차변경이 신청되었습니다.',
        };
      }
      return {};
    });

    const chgDistCrseSn = ref(0);
    const relToggle = ref(false);
    const rels = ref([]);
    const items = ref([]);
    const otherRels = computed(() => rels.value.filter(x => x.distCrseSn !== distCrseSn.value));
    const grpExist = ref(false);
    const grpDate = ref(0);

    const validate = () => {
      let result = false;
      let msg = '';
      if (!isAgree.value) {
        msg = changeMessages.value.agree + ' 동의 체크해주세요.';
      } else if (regCrseAplySttDcd.value === crseAplyDcds.REG_CRSE_APLY_STT_CHANGE && chgDistCrseSn.value === 0) {
        msg = '변경할 차수를 선택해주세요.';
      } else {
        result = true;
      }

      if (!result) {
        showMessage(msg);
      }
      return result;
    };

    const validate2 = () => {
      if (regCrseAplySttDcd.value === crseAplyDcds.REG_CRSE_APLY_STT_CANCEL) {
        if (dist.value.takeSttDcd === crseAplyDcds.TAKE_STT_COMPLETE) {
          showMessage('승인된 연수는 신청 취소가 불가능합니다.');
          return false;
        }

        if (!editable.rtrcn) {
          showMessage('해당 연수는 연수신청 취소가 불가능합니다.');
          return false;
        }
      }

      if (regCrseAplySttDcd.value === crseAplyDcds.REG_CRSE_APLY_STT_CHANGE) {
        // if (props.target.takeSttDcd === crseAplyDcds.TAKE_STT_COMPLETE) {
        //   showMessage('승인된 연수는 회차변경이 불가능합니다.');
        //   return false;
        // }

        if (!editable.seqChg) {
          showMessage('해당 연수는 회차변경이 불가능합니다.');
          return false;
        }
      }
      return true;
    };

    const validate3 = () => {
      let result = true;
      let msg = '';
      grpExist.value = false;
      if (regCrseAplySttDcd.value === crseAplyDcds.REG_CRSE_APLY_STT_CHANGE) {
        // 집합평가객체가 동일한 일자를 2개이상 가지고 있는 경우
        const grpEvlExist = lrnerObjs.value.filter(x => x.grpEvlCnt >= 2);
        if (grpEvlExist.length > 0) {
          const sameGrpEvlDate = grpEvlExist.filter(x => objItems.value.some(y => y.objBgngDt === x.objBgngDt));
          if (sameGrpEvlDate.length > 0) {
            grpExist.value = true;
            grpDate.value = timestampToDateFormat(sameGrpEvlDate[0].objBgngDt, 'yyyy.MM.dd');
          }
        }

        // 사이버평가객체가 동일한 일자를 2개이상 가지고 있는 경우
        const elseEvlExist = lrnerObjs.value.filter(x => x.gnrlEvlCnt >= 2);
        if (elseEvlExist.length > 0) {
          const sameElseEvlDate = elseEvlExist.filter(x => objItems.value.some(y => y.objBgngDt === x.objBgngDt));
          if (sameElseEvlDate.length > 0) {
            result = false;
            msg = '평가일자가 ['.concat(timestampToDateFormat(sameElseEvlDate[0].objBgngDt, 'yyyy.MM.dd'), '] 로 ' +
                '동일한 과정을<br>이미 2개 과정 신청하셨으므로<br>추가신청이 불가합니다.');
          }
        }
      }

      if (!result) {
        showMessage(msg);
      }
      return result;
    }

    const clickChange = () => {
      if (isLoading.value) return;

      isLoading.value = true;

      if (regCrseAplySttDcd.value === crseAplyDcds.REG_CRSE_APLY_STT_CHANGE) {
        getValdList();
      }

      setTimeout(() => {
        if (validate() && validate2() && validate3()) {
          const params = {regCrseAplySttDcd: regCrseAplySttDcd.value, memo: reason.value};
          if (regCrseAplySttDcd.value === crseAplyDcds.REG_CRSE_APLY_STT_CANCEL) {// 취소일 경우
            params.distCrseSn = distCrseSn.value;
            params.causeDistCrseSn = 0;
            if (dist.value.rtrcnAprvProc == '2' || dist.value.rtrcnAprvProc == '9') {// 부점장 승인을 사용하지 않는경우
              params.aplyViceMgrAprvYn = 'N';
            } else {
              params.aplyViceMgrAprvYn = 'Y';
            }
            if (dist.value.rtrcnAprvProc == '2' || dist.value.rtrcnAprvProc == '3') {// 연수원 승인을 사용하는경우
              params.atrzPhase = 3;
            } else {
              params.atrzPhase = 1;
            }
          } else if (regCrseAplySttDcd.value === crseAplyDcds.REG_CRSE_APLY_STT_NOSW) {// 불참일 경우
            params.distCrseSn = distCrseSn.value;
            params.causeDistCrseSn = 0;
            if (dist.value.absdAprvProc == '2' || dist.value.absdAprvProc == '9') {// 부점장 승인을 사용하지 않는경우
              params.aplyViceMgrAprvYn = 'N';
            } else {
              params.aplyViceMgrAprvYn = 'Y';
            }
            if (dist.value.absdAprvProc == '2' || dist.value.absdAprvProc == '3') {// 연수원 승인을 사용하는경우
              params.atrzPhase = 3;
            } else {
              params.atrzPhase = 1;
            }
          } else if (regCrseAplySttDcd.value === crseAplyDcds.REG_CRSE_APLY_STT_CHANGE) {//회차 변경일 경우
            params.distCrseSn = chgDistCrseSn.value;
            params.causeDistCrseSn = distCrseSn.value;
            if (dist.value.seqChgAprvProc == '2' || dist.value.seqChgAprvProc == '9') {// 부점장 승인을 사용하지 않는경우
              params.aplyViceMgrAprvYn = 'N';
            } else {
              params.aplyViceMgrAprvYn = 'Y';
            }
            if (dist.value.seqChgAprvProc == '2' || dist.value.seqChgAprvProc == '3') {// 연수원 승인을 사용하는경우
              params.atrzPhase = 3;
            } else {
              params.atrzPhase = 1;
            }
          } else {
            return false;
          }
          let cMsg = '';
          if (grpExist.value) {
            cMsg += '집합평가일자가 ['.concat(grpDate.value,'] 로 동일한 과정을<br>이미 2개 이상 신청하셨습니다.<br>' +
                    '연수신청은 가능하나 수료에 차질이없는지<br>신중하게 결정하시기 바랍니다.<br>');
          }
          // console.log(params);
          showConfirm({
            title: changeMessages.value.title,
            text: cMsg.concat(changeMessages.value.text),
            callback: () => {
              store.dispatch(`course/${ACT_UPDATE_CRSE_REG_APLY}`, {
                regCrseAplySn: dist.value.regCrseAplySn,
                params,
              }).then(res => {
                if (isSuccess(res)) {
                  showMessage({
                    title: '',
                    text: changeMessages.value.complete,
                    callback: () => {
                      if(dist.value.splCrseTyCdDcd=== '2066030'){
                        router.push({name: 'TrainGlobal'});
                      }else {
                        router.push({name: 'ApplyStatus'});
                      }
                    }
                  });
                } else {
                  showMessage(getResult(res).message);
                  isLoading.value = false;
                }
              }).catch(e => {
                console.error(e);
                showMessage('오류가 발생했습니다.');
                isLoading.value = false;
              });
            },
            closeCallback: () => {
              isLoading.value = false;
            },

          });

        } else {
          isLoading.value = false;
        }
      },regCrseAplySttDcd.value === crseAplyDcds.REG_CRSE_APLY_STT_CHANGE ? 600 : 0)
    };

    const clickBack = () => {
      goBack();
    };

    const getValdList = () => {
      getListFunc(`course/${ACT_GET_CRSE_DIST_OBJ_APPLY}`, chgDistCrseSn.value, objItems, null);
    }

    const limitOver = (peoplCnt, aplyRegCnt) => {
      return (peoplCnt > 0 && peoplCnt <= aplyRegCnt);
    };

    const excludeDist = [202333, 202334];
    // 회차 변경이 가능하면 관련 리스트 조회
    onMounted(() => {
      getFunc(`course/${ACT_GET_DIGITAL_DTL}`, {}, dtlItem, () => {});
      getListFunc(`course/${ACT_GET_CRSE_REG_APLY_LIST}`, {...paging}, items, paging, () => {
        items.value = items.value.map(x => ({...x}));
        // console.log(items.value.filter(x=> x.splCrseTyCdDcd =='2066030'))
        dist.value = items.value.filter(x => x.distCrseSn == distCrseSn.value &&
            (x.regCrseAplySttDcd === crseAplyDcds.REG_CRSE_APLY_STT_APPLY   ||
                x.regCrseAplySttDcd === crseAplyDcds.REG_CRSE_APLY_STT_CHANGE) &&
            x.takeSttDcd !== crseAplyDcds.TAKE_STT_REJECT )[0];
        // console.log(dist.value);
        setEditable();
      });
      getListFunc(`course/${ACT_GET_CRSE_REL_LIST}`, distCrseSn.value, rels, null, () => {
        const currentTs = new Date().getTime();
        rels.value = rels.value.filter(x =>
            // x.seqChgYn === 'Y' && (!limitOver(x.peoplCnt, x.aplyRegCnt) && currentTs >= x.seqChgBgngDt && currentTs <= x.seqChgEndDt) && x.distCrseSn != distCrseSn.value
            x.seqChgYn === 'Y' && (currentTs >= x.seqChgBgngDt && currentTs <= x.seqChgEndDt) && x.distCrseSn != distCrseSn.value
        ).map(y => ({...y, crseNm: y.crseNm.concat(limitOver(y.peoplCnt, y.aplyRegCnt) ? ' - 신청정원초과' : '')}));
        if (dtlItem.digiDeptTyDcd === '2112001' && session.value.susdyCdDcd === '2109001') {
          rels.value = rels.value.filter(y => !excludeDist.includes(y.distCrseSn));
        }
      });
      getListFunc(`course/${ACT_GET_CRSE_DIST_OBJ_LRNER}`, {}, lrnerObjs, null);
      getListFunc(`learn/${ACT_GET_LEARN_HISTORY_LIST}`, {}, learns, null, () => {
        learns.value = learns.value.filter(y => y.auditCfmtnCd === 'Y').filter(x => x.fnshYn === 'Y');
      });
    });

    return {
      isAgree,
      regCrseAplySttDcd,
      reason,
      chgDistCrseSn,
      relToggle,
      otherRels,
      editable,
      clickChange,
      timestampToDateFormat,

      crseAplyDcds,
      dist,
      clickBack,
    };

  },
};
</script>
